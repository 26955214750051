
import { defineComponent, onMounted, ref } from "vue";
import { merchants } from "@/store";
import {
  IonContent,
  IonPage,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonBackButton,
  IonSkeletonText,
  IonTitle,
  IonRow,
  IonCol,
  IonList,
  IonGrid,
} from "@ionic/vue";
import TransactionListItem from "./components/TransactionListItem.vue";
import { openToast } from "@/composables/ionicControllers";
import { formatAmount } from "@/lib/helper";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonBackButton,
    IonSkeletonText,
    IonTitle,
    IonRow,
    IonCol,
    IonList,
    IonGrid,
    TransactionListItem,
  },
  setup() {
    const transactions = ref<any>([]);
    const totalBalance = ref<any>();
    const settlement = ref<any>();
    const loadingTransactions = ref<boolean>(false);
    const { loadAllTransactions } = merchants;
    onMounted(async () => {
      loadingTransactions.value = true;
      const result = await loadAllTransactions();
      if (result.ok) {
        loadingTransactions.value = false;
        transactions.value = result.data.transactions.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() -
            new Date(a.createdAt).getTime(),
        );
        totalBalance.value = result.data.total;
        settlement.value = result.data.totalSettlement;
      } else {
        loadingTransactions.value = false;
        await openToast(result.message as string);
      }
    });

    return {
      pageTitle: "Earnings",
      loadingTransactions,
      transactions,
      totalBalance,
      settlement,
      formatAmount,
    };
  },
});
