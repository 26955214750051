import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46def09c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    button: "true",
    onClick: _ctx.openModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "ion-no-padding" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "7" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.transaction?.customer.name || '-'), 1),
                          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.transaction?.jobs[0]?.pickup.address || '--'), 1),
                          _createElementVNode("small", null, _toDisplayString(_ctx.formatDate(_ctx.transaction?.createdAt) || '--'), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "3",
                    class: "ion-text-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("h3", null, "N" + _toDisplayString(_ctx.formatAmount(_ctx.transaction?.settled / 100)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "2",
                    class: "ion-text-justify-center ion-text-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        md: _ctx.reorderTwoOutline,
                        ios: _ctx.reorderTwoSharp
                      }, null, 8, ["md", "ios"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}