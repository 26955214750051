
import { IonIcon, IonButton, IonContent } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { formatAmount } from '@/lib/helper';

export default defineComponent({
  components: { IonIcon, IonButton, IonContent },
  props: {
    close: { type: Function },
    transaction: { type: Object },
  },
  setup() {
    return {
      closeOutline,
      formatAmount,
    };
  },
});
